
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import contacts from "@/core/data/contacts";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";

interface KTMessage {
  type: string;
  name?: string;
  image: string;
  time: string;
  text: string;
}
interface RequestForm {
  address: string;
  standard: string;
  bedrooms: number;
  floor: number;
  simple_beds: number;
  double_beds: number;
  bunk_beds: number;
  sofa_beds: number;
  elevator: boolean;
  wifi: boolean;
}

export default defineComponent({
  name: "private-chat",

  setup() {
    const store = useStore();

    let advert = computed(() => {
      return store.getters.currentResultsV1;
    });
    const route = useRoute();

    const formData = ref<RequestForm>({
      address: "",
      standard: "functional",
      bedrooms: 0,
      floor: 0,
      simple_beds: 0,
      double_beds: 0,
      bunk_beds: 0,
      sofa_beds: 0,
      wifi: true,
      elevator: false,
    });


    const requestNewAdvert = async (values) => {
      console.log(" VAlues : ", values);
      for (const item in values) {
        // eslint-disable-next-line no-prototype-builtins
        if (values.hasOwnProperty(item)) {
          if (values[item]) {
            formData.value[item] = values[item];
          }
        }
      }
      await store.dispatch(Actions.GET_ADVERT_V1, formData.value);
    };

    return {
      requestNewAdvert,
      formData,
      advert,
    };
  },
});
